@import "../../variables.scss";

.resetPassword {
  background-image: url("../../assets/images/splash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80vw;
    padding: 40px;
    height: 80vh;
    background-color: rgba($primary, 0.9);
    color: $light;

    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;

      input {
        height: 40px;
      }

      label {
        margin: 20px 0 10px 0;
      }

      .error {
        color: #df0000;
      }
    }
    .button {
      margin-top: 20px;
      @include secondaryButton;
    }
  }
}
