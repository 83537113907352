@import "../../variables.scss";

.dashboard {
  display: flex;
  height: 100vh;

  .sidebar {
    background-color: $primary;
    color: $light;
    padding: $base-spacing * 4;
    width: 25%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form {
      width: 100%;
      // display: flex;
      // flex-direction: column;
    }

    .signOut {
      button {
        @include primaryButton;
      }
    }
  }

  .centerGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 75%;

    .grid {
      display: flex;
      margin-top: $base-spacing * 2;
      max-width: 100%;
      justify-content: center;
      // width: 80vw;
    }
  }
}

@media only screen and (max-width: 769px) {
  .dashboard {
    & > svg {
      position: absolute;
      top: 20px;
      left: 20px;
      height: 1em;
    }

    .sidebar {
      width: 100%;
      height: 100%;
      position: fixed;
      transition: 0.5s;
    }

    .hide {
      width: 0%;
      padding: 0;

      * {
        display: none;
      }
    }

    .centerGrid {
      min-width: 100%;
    }
  }
}

@media only screen and (max-width: 425px) {
  .dashboard {
    .centerGrid {
      min-width: unset;
    }
  }
}
