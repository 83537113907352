@import "../../variables.scss";

.login {
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;

    label {
      margin: 20px 0 10px 0;
    }

    .button {
      margin-top: 20px;
      @include secondaryButton;
    }
  }
  a {
    color: $light;

    &:visited {
      color: $light;
    }
  }
}
