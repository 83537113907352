@import "../../variables.scss";

.landingPage {
  background-image: url("../../assets/images/splash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80vw;
    padding: 40px;
    height: 80vh;
    background-color: rgba($primary, 0.9);
    color: $light;

    input {
      height: 40px;
    }

    .button {
      @include primaryButton;
    }
  }
}
