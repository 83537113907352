@import "./variables.scss";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.button {
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: block;
  padding: 10px;
  color: black;
  background-color: rgb(75, 106, 114);
  // margin-top: auto;
  border: 2px solid rgb(88, 111, 117);

  &:hover,
  &:focus {
    background-color: rgb(88, 111, 117);
    border-color: rgb(88, 111, 117);
    cursor: pointer;
    color: white;
  }
}

.secondary {
  background-color: #0000007c;
  color: white;
}
